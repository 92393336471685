import InitiumIcon from "assets/initium-logos/brandmark-colored.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import {
  AnimatePresence,
  motion,
  stagger,
  useMotionValueEvent,
  useTime,
  useTransform,
} from "framer-motion";
import { useEffect, useState } from "react";

const messages = [
  {
    right: "How many agency guidelines does Initium AI help with?",
    left: "Initium AI currently supports 13 organization guidelines and can answer questions related to them.",
  },
  {
    right: "How can I know what your helper is saying is accurate?",
    left: "While we cannot guarantee our answers are perfect, we provide all references used to support the information we offer.",
  },
  {
    right: "Why work on grants?",
    left: "With 25+ years of grant writing experience, we see this as a great opportunity to impact a field we care about.",
  },
];

const LeftMessage = ({ message }) => {
  return (
    <motion.div
      className="flex w-full justify-start items-end gap-x-2"
      initial={{
        translateY: "16rem",
        transition: {
          type: "spring",
          bounce: 0,
        },
      }}
      animate={{
        translateY: "0",
        transition: {
          type: "spring",
          bounce: 0,
          duration: 1,
          delay: 2.5,
        },
      }}
      exit={{
        type: "linear",
        y: "-1rem",
        opacity: 0,
      }}
    >
      <img
        src={InitiumIcon}
        className="h-8 w-8 translate-y-4 bg-slate-200 rounded-full"
        alt="Initium Logo"
      />
      <div className="bg-slate-200 max-w-[22.5rem] px-4 py-1 font-semibold rounded-2xl rounded-es-none flex items-center justify-center text-sm">
        {message}
      </div>
    </motion.div>
  );
};

const RightMessage = ({ message }) => {
  return (
    <motion.div
      className="flex w-full items-end justify-end gap-x-2"
      initial={{
        translateY: "16rem",
        transition: {
          type: "spring",
          bounce: 0,
        },
      }}
      animate={{
        translateY: "0",
        transition: {
          type: "spring",
          bounce: 0,
          duration: 1,
          delay: 1,
        },
      }}
      exit={{
        type: "linear",
        y: "-1rem",
        opacity: 0,
      }}
    >
      <div className="bg-slate-200 max-w-[22.5rem] px-4 py-1 font-semibold rounded-2xl rounded-ee-none flex items-center justify-center text-sm">
        {message}
      </div>
      <PersonIcon className="min-h-8 min-w-8 h-8 w-8 translate-y-4 bg-slate-200 rounded-full" />
    </motion.div>
  );
};

export default function QuestionsFeature2() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((p) => (p === messages.length - 1 ? 0 : p + 1));
    }, 8000);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <div className="col-span-2 md:col-span-1 flex flex-col justify-between bg-white shadow-lg group hover:bg-slate-50 transition-colors relative grow">
      <motion.div className="overflow-y-hidden h-72 sm:h-52 px-8 pt-8 flex flex-col gap-y-6 relative">
        <AnimatePresence>
          <RightMessage
            key={"right-" + index}
            message={messages[index].right}
          />
          <LeftMessage key={"left-" + index} message={messages[index].left} />
        </AnimatePresence>
      </motion.div>
      <div className="px-8 pb-8 mt-6 group-hover:-translate-y-6 transition-transform">
        <div className="header text-2xl">Answer Questions</div>
        <div className="text-xl">
          Your personal chatbot turns 100s of pages of requirements into a
          paragraph of exactly what you need.
        </div>
      </div>
      <div className="h-2  bg-gradient-to-r from-fuschia to-blue transition-all" />
    </div>
  );
}
