import { Outlet, useLocation } from "react-router";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export default function MainLayout() {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }
  const { hash } = useLocation();
  // Run only once on page load
  useEffect(() => {
    const element = document.getElementById(hash.substring(1));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}
