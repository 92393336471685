import { ReactComponent as Arrow } from "assets/hand-drawn/arrows/arrow-curve-up-left.svg";

export default function Pricing() {
  return (
    <div id="pricing" className="flex justify-center">
      <div className="flex justify-center bg-gradient-to-br from-fuschia p-2  w-full max-w-screen-xl  to-blue  rounded-lg">
        <div className="bg-light-beige rounded-md p-16 w-full text-center flex justify-center shadow-xl">
          <div className="flex flex-col items-center justify-center max-w-md gap-y-6">
            <div className="text-3xl font-bold">First month is on us</div>
            <div className="text-xl font-normal">
              During our beta release, all features will be free.
            </div>
            <div className="text-xl font-normal">
              See how much faster your grant-writing process becomes.
            </div>

            <div className="relative mt-8">
              <span className="hidden md:block accent text-4xl -left-[170%] -top-20 absolute">
                Try it out!
              </span>
              <Arrow
                className="hidden md:block absolute rotate-180 -left-[150%] -top-12"
                width="200"
                height="100"
              />
              <a
                href="https://app.initium.ai/"
                className="bg-gradient-to-r from-fuschia to-blue hover:to-fuschia hover:text-white transition-all text-white rounded-full uppercase font-semibold px-6 py-4 text-2xl "
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
