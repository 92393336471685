import BGGraphic from "assets/textured-graphics/Initium AI_Branding_Abstract Graphic 4 - Black.svg";

export default function ContactUs() {
  return (
    <div className="flex flex-col gap-y-16 overflow-hidden h-screen w-screen text-center items-center justify-center relative px-4">
      <span className="max-w-5xl text-3xl md:text-5xl center font-bold leading-tight">
        Contact Us
      </span>
      <div className="header text-2xl  sm:text-large underline fuschia">
        contact@initium.ai
      </div>

      <div className="absolute h-full w-full overflow-clip -z-10">
        <img
          src={BGGraphic}
          className="absolute origin-center -scale-x-125 rotate-[30deg] translate-x-[15%] translate-y-12 lg:scale-x-100 lg:rotate-[30deg] lg:translate-x-[80%] lg:w-3/4 lg:translate-y-[20%]"
          alt=""
        />
        <img
          src={BGGraphic}
          className="absolute origin-center grow-0 bottom-0 translate-y-1/4  -translate-x-[10%] -scale-150 rotate-[30deg] lg:-translate-x-1/2 lg:translate-y-0 lg:-scale-100"
          alt=""
        />
      </div>
    </div>
  );
}
