import BrandmarkColored from "assets/initium-logos/primary-horizontal-colored-white.svg";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
  useVelocity,
} from "framer-motion";
import { ReactComponent as Hamburger } from "assets/icons/hamburger.svg";
import { useState } from "react";

export default function Navbar() {
  return (
    <>
      <DesktopNav />
      <MobileNav />
    </>
  );
}

const DesktopNav = () => {
  // Controls if navbar appears
  const { scrollYProgress } = useScroll();
  const [isHovering, setIsHovering] = useState(false);
  const [hasScrolledUp, setHasScrolledUp] = useState(true);
  const top = useTransform(() => (!hasScrolledUp && !isHovering ? "-100%" : 0));
  const scrollVelocity = useVelocity(scrollYProgress);

  useMotionValueEvent(scrollVelocity, "change", (latest) => {
    if (latest < 0) setHasScrolledUp(true);
    else if (latest > 0) setHasScrolledUp(false);
  });
  return (
    <nav
      className="hidden md:block fixed w-full z-[51] h-24 top-0"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <motion.div
        className="w-full flex flex-col text-white fixed z-50 transition-transform duration-300"
        style={{ translateY: top }}
      >
        <div className="flex justify-between bg-black px-8 py-4 ">
          <a href="/">
            <img src={BrandmarkColored} className="h-16" alt="Initium AI" />
          </a>
          <div className="flex text-lg gap-x-6 lg:gap-x-12 items-center font-semibold px-8 whitespace-nowrap">
            <a href="/#home">Home</a>
            <a href="/#product">Product</a>
            <a href="/#about-us">About Us</a>
            <a href="/contact-us">Contact Us</a>
            <a href="/blogs">Blog</a>
          </div>
        </div>
        <div className="h-1 bg-gradient-to-r from-fuschia to-blue" />
      </motion.div>
    </nav>
  );
};

const MobileNav = () => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  return (
    <nav className="md:hidden fixed w-full z-50 h-24 top-0">
      <div className="w-full flex flex-col text-white text-xl transition-transform duration-300 bg-black">
        <div className="flex justify-between bg-black px-4 py-4">
          <a href="/">
            <img src={BrandmarkColored} className="h-16" alt="Initium AI" />
          </a>
          <button
            className="hover:text-fuschia text-white"
            onClick={() => setIsDropdownMenuOpen((o) => !o)}
          >
            <Hamburger className="h-8 w-8" />
          </button>
        </div>
        <AnimatePresence>
          {isDropdownMenuOpen && (
            <motion.div
              className="flex pb-4 px-4 lg:hidden flex-col items-end gap-y-4 font-semibold overflow-hidden bg-black"
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: "auto" },
                collapsed: { height: 0 },
              }}
              transition={{ duration: 0.2, ease: "easeOut" }}
            >
              <a href="/#home" className="px-4">
                Home
              </a>
              <a href="/#product" className="px-4">
                Product
              </a>
              <a href="/#about-us" className="px-4">
                About Us
              </a>
              <a href="/contact-us" className="px-4">
                Contact Us
              </a>
              <a href="/blogs" className="px-4">
                Blog
              </a>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="h-1 bg-gradient-to-r from-fuschia to-blue" />
      </div>
    </nav>
  );
};
