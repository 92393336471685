import { useInView } from "framer-motion";
import { useRef } from "react";
import RadaPic from "assets/images/rada.jpg";
import SpencerPic from "assets/images/spencer.jpg";

export default function AboutUs() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div id="about-us" className="flex flex-col p-16 relative overflow-x-clip w-full">
      <div className="flex bg-[url('assets/textured-graphics/10-black.svg')] bg-no-repeat bg-contain  opacity-40 min-w-[1800px] max-w-[1800px] w-full -ml-16 my-16 scale-x-125 h-full top-0 absolute -z-50  " />
      <div className="font-semibold text-5xl mb-32 text-center">Who's Behind Initium AI</div>
      <div
        ref={ref}
        aria-hidden={!isInView}
        className="flex flex-wrap justify-center items-center gap-y-16 gap-x-32 aria-hidden:opacity-0 transition-opacity duration-1000 "
      >
        <div className="flex flex-col max-w-md items-center justify-center gap-y-4">
          <img
            src={RadaPic}
            className="bg-beige rounded-full h-64  w-64 ring-4 ring-white"
            alt="Rada's Profile"
          />
          <div className="header text-center">
            <div className="font-bold text-2xl">Rada</div>
            <div className="text-xl text-fuschia uppercase font-bold">
              CEO & Co-Founder
            </div>
          </div>
          <div className="text-lg text-center">
            A professor at the University of Michigan, Rada has been grant
            writing for the past 20+ years. Awarded 50 grants worth ~$20
            million, she now wants to see more innovative ideas get funded to
            shape the future of research.
          </div>
        </div>
        <div className="flex flex-col max-w-md items-center gap-y-4">
          <img
            src={SpencerPic}
            className="bg-beige rounded-full h-64 w-64 ring-4 ring-white"
            alt="Spencer's Profile"
          />
          <div className="header text-center">
            <div className="font-bold text-2xl">Spencer</div>
            <div className="text-xl text-fuschia uppercase font-bold">
              CTO & Co-Founder
            </div>
          </div>
          <div className="text-lg text-center">
            A Data Science grad from the University of Michigan, Spencer
            specializes in Machine Learning and Natural Language Processing.
            Also a researcher - he hopes to combine his technical skills with
            his passion for new ideas to innovate in a space that matters.
          </div>
        </div>
      </div>
    </div>
  );
}
