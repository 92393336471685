import {
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
import { useRef } from "react";

const rawText =
  "Grant applications are challenging, but we're here to support you through the entire process, from identifying funding opportunities to providing feedback on your writing.";
const textArray = rawText.split(" ");
const N = textArray.length;

const WordText = ({ word, scrollYProgress, index }) => {
  const scaledOpacity = useTransform(() =>
    scrollYProgress.get() > index / N ? 1 : 0.2
  );

  return (
    <motion.span
      className="transition-opacity duration-200"
      style={{ opacity: scaledOpacity }}
    >
      {word}
    </motion.span>
  );
};

export default function Support() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });

  return (
    <section ref={ref} className="h-[150vh] bg-blue font-normal relative px-4">
      <div className="text-white sticky top-0 h-screen flex items-center justify-center text-3xl md:text-6xl">
        <div className="max-w-screen-xl px-4 whitespace-normal text-wrap flex flex-wrap gap-x-4">
          {textArray.map((s, i) => (
            <WordText
              scrollYProgress={scrollYProgress}
              key={i}
              index={i}
              word={s}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
