import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import "./index.css";
import Home from "./pages/home/Home";
import Error from "pages/error/Error";
import PrivacyPolicy from "pages/privacy-policy/PrivacyPolicy";
import MainLayout from "layout/MainLayout";
import TermsAndConditions from "pages/terms-and-conditions/TermsAndConditions";
import ContactUs from "pages/contact-us/ContactUs";
import Blogs from "pages/blogs/Blogs";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<MainLayout />} errorElement={<Error />}>
      <Route path="" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/blogs" element={<Blogs />} />
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
