import BGGraphic from "assets/textured-graphics/7-black.svg";

export default function MissionStatement() {
  return (
    <div className="bg-light-beige w-full text-center py-32 flex flex-col gap-y-6 items-center overflow-hidden relative">
      <img
        src={BGGraphic}
        className="fill-black absolute h-[150%] -translate-y-[25%] -translate-x-[95%]"
        alt=""
      />
      <img
        src={BGGraphic}
        className="fill-black absolute h-[125%] -translate-y-[20%] translate-x-[110%]"
        alt=""
      />
      <div>
        <div className="accent text-6xl leading-none">Our Mission</div>
        <div className="font-semibold text-4xl mb-4">Faster Funds</div>
      </div>
      <div className="header text-2xl max-w-2xl ">
        Save money and time with holistic AI-based support throughout the entire
        grant writing process.
      </div>
      <div className="text-2xl font-semibold mb-4">
        Your writing. Our everything else.
      </div>
      <div>
        <a
          href="https://app.initium.ai/"
          className="bg-gradient-to-r from-fuschia to-blue hover:to-fuschia hover:text-white transition-all text-white rounded-full uppercase font-semibold px-6 py-4 text-2xl "
        >
          Sign Up
        </a>
      </div>
    </div>
  );
}
