import Graphic from "assets/product-graphics/Initium AI Website Graphics-07.svg"

export default function BrainstormFeature() {
  return (
    <div className="col-span-2 md:col-span-1 flex flex-col bg-white shadow-lg group hover:bg-slate-50 transition-colors relative grow">
      <div className="grow p-4">
        <img src={Graphic} />
      </div>
      <div className="header px-8 text-2xl mt-6 group-hover:-translate-y-6 transition-transform">
        Brainstorm Ideas
      </div>
      <div className="text-xl px-8 pb-8 group-hover:-translate-y-6 transition-transform">
        Delve deep into your research idea to create the perfect one for the
        grant you're applying to.
      </div>
      <div className="h-2 bg-gradient-to-r from-fuschia to-blue" />
    </div>
  );
}
