// import Brandmark from "assets/initium-logos/brandmark-colored.svg";
import AdminFeature from "./AdminFeature";
import BrainstormFeature from "./BrainstormFeature";
import Feature from "./Feature";
import FindCallsFeature from "./FindCallsFeature";
import QuestionsFeature from "./QuestionsFeature";
import QuestionsFeature2 from "./QuestionsFeature2";
import RedteamReview from "./RedteamReviewFeature";

export default function Product() {
  return (
    <div
      id="product"
      className="bg-pastel-blue flex flex-col gap-y-6 py-12 px-4 items-center"
    >
      <div className="text-fuschia text-center sub-header uppercase text-3xl">
        Your Powerful Grant Copilot
      </div>
      <div className="font-semibold text-4xl text-center">
        Find. Organize. Perfect.
      </div>
      <div className="text-lg text-center">
        Features that supercharge the entire grant-writing pipeline.
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg gap-4 mt-8">
        <FindCallsFeature />
        <BrainstormFeature />
        <AdminFeature />
        <RedteamReview />
        <QuestionsFeature2 />
      </div>
    </div>
  );
}
