import Graphic from "assets/product-graphics/Initium AI Website Graphics-02.svg"
export default function AdminFeature() {
  return (
    <div className="col-span-2 md:col-span-1 flex flex-col bg-white shadow-lg group hover:bg-slate-50 transition-colors relative grow">
      {/* <div className="flex flex-col divide-y-2 border-y-2 relative h-32 px-8 pt-8">
        <div className="flex justify-between font-semibold items-center">
          <div className="accent text-3xl leading-none line-through">
            Launch website
          </div>
          <div className="flex h-fit items-center bg-green-500 px-2 rounded-md">
            Done
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-between font-semibold items-center">
          <div className="accent text-3xl">Create the best grant copilot</div>
          <div className="flex h-fit">
            <span className="bg-yellow-300 px-2 rounded-md">In Progress</span>
          </div>
        </div>
      </div> */}
      <div className="grow p-2">
        <img src={Graphic} />
      </div>

      <div className="header text-2xl px-8 mt-6 group-hover:-translate-y-6 transition-transform">
        Ditch Admin
      </div>
      <div className="text-xl px-8 pb-8 group-hover:-translate-y-6 transition-transform">
        Quick access to required files from the call. Set due dates and action
        items to spend more time innovating, not reading requirements and
        planning.
      </div>
      <div className="h-2 bg-gradient-to-r from-fuschia to-blue" />
    </div>
  );
}
