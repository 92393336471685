import { ReactComponent as InitiumLogo } from "assets/initium-logos/primary-stacked-colored-white.svg";
import { ReactComponent as LinkedinLogo } from "assets/other-logos/linkedin.svg";
import { ReactComponent as TwitterLogo } from "assets/other-logos/twitter-x.svg";

export default function Footer() {
  return (
    <div className="bg-black flex flex-col justify-center items-center gap-y-16 gap-x-8 pt-16 pb-8 px-8 text-white">
      <div className="max-w-screen-xl w-full flex flex-wrap gap-x-8 gap-y-16 justify-center md:justify-between">
        <a href="/">
          <InitiumLogo className="w-80" />
        </a>
        <div className="grid grid-cols-2 gap-x-16 gap-y-4 text-xl font-normal">
          <div className="col-span-2 font-bold uppercase text-2xl text-fuschia">
            Quick Links
          </div>
          <a href="#home">Home</a>
          <a href="/blogs">Blog</a>
          <a href="#product">Product</a>
          <a href="#pricing">Pricing</a>
          <a href="#about-us">About Us</a>
          <a href="#faq">FAQ</a>
          <a href="/contact-us">Contact Us</a>
          <div className="flex gap-x-4">
            <a href="https://www.linkedin.com/company/initium-ai-inc">
              <LinkedinLogo className="h-6 w-6 fill-white hover:fill-fuschia" />
            </a>
            <a href="https://x.com/Initium_AI">
              <TwitterLogo className="h-6 w-6 fill-white hover:fill-fuschia" />
            </a>
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl w-full flex flex-wrap gap-x-8 gap-y-4 justify-center md:justify-between font-normal">
        <div>2024 Initium AI Inc. All Rights Reserved.</div>
        <div>
          <a href="/privacy-policy">Privacy Policy</a>
          <span className="mx-2">|</span>
          <a href="/terms-and-conditions">Terms and Conditions</a>
        </div>
      </div>
      {/* <div className="flex flex-col items-center gap-y-6 mx-auto">
        <a href="/">
          <InitiumLogo className="w-60" />
        </a>
        <div>2024 Initium AI Inc. All Rights Reserved.</div>
        <div>
          <a href="/privacy-policy">Privacy Policy</a>
          <span className="mx-2">|</span>
          <a href="/terms-and-conditions">Terms and Conditions</a>
        </div>
        <div className="flex gap-x-4">
          <a href="https://www.linkedin.com/company/initium-ai-inc">
            <LinkedinLogo className="h-8 w-8 fill-[#0072b1] bg-white rounded-md" />
          </a>
          <a href="https://x.com/Initium_AI">
            <TwitterLogo className="h-8 w-8 hover:text-white" />
          </a>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-12 gap-y-4 text-center mx-auto">
        <div className="col-span-2 header text-2xl">Quick Links</div>
        <a href="#home">Home</a>
        <a href="/blogs">Blog</a>
        <a href="#product">Product</a>
        <a href="#pricing">Pricing</a>
        <a href="#about-us">About Us</a>
        <a href="#faq">FAQ</a>
        <a href="/contact-us">Contact Us</a>
      </div> */}
    </div>
  );
}
