import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { ReactComponent as Minus } from "assets/icons/minus.svg";
import { ReactComponent as Plus } from "assets/icons/plus.svg";

export default function Question({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="group border-2 aria-expanded:border-blue aria-expanded:bg-pastel-blue transition-colors rounded-lg p-4"
      aria-expanded={isOpen}
    >
      <button
        className="text-xl w-full text-left font-semibold flex justify-between group-aria-expanded:text-blue hover:underline"
        onClick={() => setIsOpen((o) => !o)}
      >
        {question}
        {isOpen ? <Minus className="w-6 h-6" /> : <Plus className="w-6 h-6" />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="w-full text-xl overflow-y-hidden font-normal pt-4"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto" },
              collapsed: { height: 0 },
            }}
            transition={{ duration: 0.2, ease: "linear" }}
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

// return (
//   <div>
//     <button
//       className="bg-beige rounded-md p-4 text-xl w-full text-left font-normal flex justify-between"
//       onClick={() => setIsOpen((o) => !o)}
//     >
//       {question}
//       {isOpen ? <Plus /> : <Minus />}
//     </button>
//     <AnimatePresence>
//       {isOpen && (
//         <motion.div
//           className="bg-light-beige w-full text-xl px-4 overflow-y-hidden"
//           initial="collapsed"
//           animate="open"
//           exit="collapsed"
//           variants={{
//             open: { height: "auto" },
//             collapsed: { height: 0 },
//           }}
//           transition={{ duration: 0.2, ease: "linear" }}
//         >
//           <div className="py-4">{answer}</div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   </div>
// );
