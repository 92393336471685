import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";

const initialCards = [
  {
    title: "Grant Recommendations",
    description:
      "Initium uses the latest advances in AI along with the information you provide us to match you with the best Grants possible",
  },
  {
    title: "Nightly Syncs",
    description:
      "We update our system daily with new Calls so that we can always provide you with the latest information",
  },
  {
    title: "Simple Search",
    description:
      "Just want to search for calls yourself? No problem. We also have a simple search feature where you can explore all of the Calls in our system",
  },
];

const cards = [...initialCards, ...initialCards];
const Card = ({ title, description, index }) => {
  return (
    <div
      className={`h-full w-60 rounded-3xl ${
        index % 2 === 0 ? "bg-fuschia" : "bg-blue"
      }`}
    >
      <div className="h-full w-full p-4 bg-beige border-[1px] border-black -translate-x-2 -translate-y-2 rounded-3xl">
        <div className="font-semibold text-center">{title}</div>
        {description}
      </div>
    </div>
  );
};

const variants = {
  enter: (direction) => {
    return {
      left: direction > 0 ? "150%" : "-150%",
    };
  },
  left: {
    left: "0%",
    zIndex: 0,
    x: "-50%",
    opacity: 0.5,
    scale: 0.8,
  },
  center: {
    left: "50%",
    zIndex: 1,
    x: "-50%",
    opacity: 1,
    scale: 1,
  },
  right: {
    left: "100%",
    zIndex: 0,
    x: "-50%",
    opacity: 0.5,
    scale: 0.8,
  },
  exit: (direction) => {
    return {
      left: direction < 0 ? "150%" : "-150%",
      zIndex: 0,
      opacity: 0,
    };
  },
};

export default function FindCallsFeature() {
  const [isHovering, setIsHovering] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  const wrap = (x) => {
    if (x >= cards.length) {
      return 0;
    } else if (x < 0) {
      return cards.length - 1;
    } else {
      return x;
    }
  };
  const paginate = (newDirection) => {
    setPage([wrap(page + newDirection), newDirection]);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isHovering) {
        paginate(1);
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [paginate, isHovering]);

  const prevIndex = wrap(page - 1);
  const nextIndex = wrap(page + 1);

  return (
    <div
      className="bg-white col-span-2 flex flex-col shadow-lg w-full group hover:bg-slate-50 transition-colors"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="flex flex-col-reverse md:flex-row">
        <div className="flex flex-col gap-y-6 md:min-w-96 md:max-w-96 p-8 group-hover:-translate-y-6 transition-transform justify-end ">
          <div className="header text-2xl">Identify Calls</div>
          <div className="text-xl">
            Find calls that apply to you, without spending days trying to find
            the right call to apply to.
          </div>
        </div>
        <div className="flex relative grow p-8 m-4 h-72 overflow-x-hidden">
          <div className="absolute left-0 top-0 flex h-full justify-center items-center z-10">
            <button
              className="h-fit hover:text-fuschia group-hover:scale-150 transition-transform"
              onClick={() => paginate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="absolute right-0 top-0 flex h-full justify-center -scale-x-100 items-center z-10">
            <button
              className="h-fit hover:text-fuschia group-hover:scale-150 transition-transform"
              onClick={() => paginate(1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              className={"absolute h-52 w-52 rounded-3xl bg-fuschia"}
              key={prevIndex}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="left"
              exit="exit"
              transition={{
                x: { type: "linear", duration: 0.1 },
                opacity: { duration: 0.1 },
              }}
            >
              <Card {...cards[prevIndex]} index={prevIndex} key={prevIndex} />
            </motion.div>
            <motion.div
              className={"absolute h-52 w-52 rounded-3xl bg-fuschia"}
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "linear", duration: 0.1 },
                opacity: { duration: 0.1 },
              }}
            >
              <Card {...cards[page]} index={page} key={page} />
            </motion.div>
            <motion.div
              className={"absolute h-52 w-52 rounded-3xl bg-fuschia"}
              key={nextIndex}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="right"
              exit="exit"
              transition={{
                x: { type: "linear", duration: 0.1 },
                opacity: { duration: 0.1 },
              }}
            >
              <Card {...cards[nextIndex]} index={nextIndex} key={nextIndex} />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <div className="h-2 bg-gradient-to-r from-fuschia to-blue" />
    </div>
  );
}
