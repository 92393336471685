import Graphic from "assets/product-graphics/Initium AI Website Graphics-01.svg";

export default function RedteamReview() {
  return (
    <div className="col-span-2 md:col-span-1 flex flex-col justify-between bg-white shadow-lg group hover:bg-slate-50 transition-colors relative grow">
      <div className="absolute origin-top-left top-0 right-0 bg-gradient-to-br from-fuschia to-blue p-2 text-white rounded-es-xl">
        <div className="origin-top-left font-semibold text-xl">On the way!</div>
      </div>
      <div className="grow p-8">
        <img src={Graphic} />
      </div>
      <div className="px-8 pb-8 mt-6 group-hover:-translate-y-6 transition-transform">
        <div className="header text-2xl">Get Redteam Reviews</div>
        <div className="text-xl">
          And the highest chance of getting the funding you need to shape the
          future you want.
        </div>
      </div>
      <div className="h-2 bg-gradient-to-r from-fuschia to-blue transition-all" />
    </div>
  );
}
