// import { ReactComponent as Circle } from "assets/hand-drawn/circles/circle-2.svg";
import { ReactComponent as Underline } from "assets/hand-drawn/underlines/double-lined-1.svg";
import { motion } from "framer-motion";
// import { useAnimate, stagger } from "framer-motion";
import BGGraphic from "assets/textured-graphics/4-full-color.svg";
import { cubicBezier } from "framer-motion";

const circleTransition = {
  duration: 1,
  delay: 0.75,
  ease: cubicBezier(0.66, 0.15, 0.81, 0.43),
};


const transitionDuration = { duration: 0.75 };

export default function IntroSection() {
  return (
    <>
      <div
        id="home"
        className="flex flex-col gap-y-16 overflow-hidden h-screen w-screen text-center items-center justify-center relative px-4"
      >
        <motion.div
          className="max-w-5xl text-3xl md:text-5xl font-semibold leading-tight"
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={transitionDuration}
        >
          <span className="">Spend more time pursuing</span>
          <span className="relative ml-6 whitespace-nowrap">
            new ideas.
            <svg
              className="absolute -top-[35%] -left-[10%] w-[120%] fill-transparent"
              id="Layer_5"
              data-name="Layer 5"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 260 96.73"
            >
              <motion.path
                d="    
              M60,30 c22.18,-7.6,46.43,-11.63,70.78,-11.98 c28.11,-0.17,56.52,1.27,83.88,6.33 c14.32,2.82,40.3,8.85,43.43,21.22 c1.23,5.25,-2.32,10.21,-7.24,13.86 c-12.82,9.31,-32.68,14.16,-49.7,17.46 c-36.45,6.71,-74.64,7.31,-111.76,3.8 c-24.7,-2.43,-79.08,-10.4,-80.34,-33.81 c-0.13,-11.1,14.99,-18.35,26.94,-22.66 c43.27,-14.99,108.5,-14.81,154.91,-8.42 c3.56,0.55,7.31,1.15,10.63,2.15 c0.76,0.26,1.67,0,1.99,-0.59
              "
                stroke="blue"
                strokeWidth="4"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={circleTransition}
              />
            </svg>
          </span>
          <div className="font-semibold text-3xl md:text-5xl">
            Not trying to fund them.
          </div>
        </motion.div>
        {/* <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={transitions[1]}
          className="font-semibold text-3xl md:text-5xl"
        ></motion.div> */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={transitionDuration}
          className=" text-2xl md:text-3xl max-w-lg"
        >
          Initium AI researches, edits, answers questions, and takes care of
          admin in the grant-writing process.
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={transitionDuration}
          className="header text-2xl md:text-3xl"
        >
          Your only job.
          <div className="inline-flex relative ml-2">
            Write.
            <svg
              className="absolute w-full h-6 -bottom-1/2 fill-none stroke-fuschia"
              preserveAspectRatio="none"
              viewBox="0 0 113 70"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                stroke="fuschia"
                strokeWidth="8"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.5, delay: 2, ease: "easeOut" }}
                d="
    M10,20.69 c0.97,-0.08,1.91,-0.34,2.86,-0.36 c2.64,-0.04,5.24,-0.46,7.88,-0.43 c0.69,0,1.37,0.02,2.05,-0.18 c0.43,-0.13,0.96,-0.07,1.45,-0.08 c1.73,-0.05,3.46,0.02,5.18,-0.28 c0.67,-0.11,1.39,-0.05,2.08,-0.05 c2.02,-0.03,4.03,-0.04,6.05,-0.08 c0.41,0,0.82,-0.08,1.23,-0.12 c0.55,-0.05,1.1,-0.12,1.65,-0.14 c1.74,-0.04,3.48,-0.05,5.22,-0.08 c0.55,0,1.09,0,1.64,-0.14 c0.45,-0.12,0.96,-0.11,1.44,-0.11 c1.74,-0.02,3.48,-0.02,5.22,-0.02 c0.61,0,1.23,-0.06,1.65,0.48 c0.11,0.15,0.68,0.19,0.97,0.11 c1.15,-0.33,2.34,-0.2,3.51,-0.27 c2.14,-0.13,4.26,-0.43,6.42,-0.38 c1.34,-0.39,2.75,-0.22,4.13,-0.29 c2.49,-0.13,4.96,-0.43,7.46,-0.37 c1.98,-0.41,4.02,-0.12,6,-0.36 c2.42,-0.3,4.86,-0.08,7.25,-0.34 c2.63,-0.28,5.28,-0.06,7.89,-0.33
  "
              ></motion.path>

              <motion.path
                stroke="fuschia"
                strokeWidth="8"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.5, delay: 2.5, ease: "easeOut" }}
                d="
    M20,50 c0.97,-0.08,1.91,-0.34,2.86,-0.36 c2.64,-0.04,5.24,-0.46,7.88,-0.43 c0.69,0,1.37,0.02,2.05,-0.18 c0.43,-0.13,0.96,-0.07,1.45,-0.08 c1.73,-0.05,3.46,0.02,5.18,-0.28 c0.67,-0.11,1.39,-0.05,2.08,-0.05 c2.02,-0.03,4.03,-0.04,6.05,-0.08 c0.41,0,0.82,-0.08,1.23,-0.12 c0.55,-0.05,1.1,-0.12,1.65,-0.14 c1.74,-0.04,3.48,-0.05,5.22,-0.08 c0.55,0,1.09,0,1.64,-0.14 c0.45,-0.12,0.96,-0.11,1.44,-0.11 c1.74,-0.02,3.48,-0.02,5.22,-0.02 c0.61,0,1.23,-0.06,1.65,0.48 c0.11,0.15,0.68,0.19,0.97,0.11 c1.15,-0.33,2.34,-0.2,3.51,-0.27 c2.14,-0.13,4.26,-0.43,6.42,-0.38 c1.34,-0.39,2.75,-0.22,4.13,-0.29 c2.49,-0.13,4.96,-0.43,7.46,-0.37 c1.98,-0.41,4.02,-0.12,6,-0.36 c2.42,-0.3,4.86,-0.08,7.25,-0.34 c2.63,-0.28,5.28,-0.06,7.89,-0.33
  "
              ></motion.path>
            </svg>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={transitionDuration}
        >
          <a
            href="https://app.initium.ai/"
            className="bg-gradient-to-r from-fuschia to-blue hover:to-fuschia hover:text-white transition-all text-white rounded-full uppercase font-semibold px-6 py-4 text-2xl "
          >
            Sign Up
          </a>
        </motion.div>
        <div className="absolute h-full w-full overflow-clip -z-10">
          <img
            src={BGGraphic}
            className="absolute translate-x-[100%] translate-y-1/4 w-2/3 origin-center rotate-[30deg]"
            alt=""
          />
          <img
            src={BGGraphic}
            className="absolute grow-0 bottom-0 -translate-x-1/2 origin-center rotate-[30deg] -scale-100"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
