import Question from "./Question";
import BGGraphic from "assets/textured-graphics/10-black.svg";

export default function FAQ() {
  return (
    <div
      id="faq"
      className="flex flex-col relative items-center justify-center py-72 overflow-hidden"
    >
      <img
        src={BGGraphic}
        className="absolute -z-10 min-w-[1800px] max-w-[1800px] -rotate-6 origin-center top-0 -translate-y-24 -translate-x-1/4"
        alt=""
      />

      <img
        src={BGGraphic}
        className="absolute -z-10 min-w-[1800px] max-w-[1800px] origin-center -rotate-6 -bottom-[15%] translate-x-52 "
        alt=""
      />

      <div className="max-w-screen-xl w-full relative hidden md:block">
        <div className="accent underline text-5xl absolute left-[10%] translate-y-5 -rotate-12 -z-10">
          Ask a Question!
        </div>
      </div>
      <div className="relative mb-10">
        <div className="header text-black text-center text-8xl">FAQ</div>
      </div>

      <div className="flex flex-col w-full gap-y-8 bg-white max-w-screen-xl px-4">
        <Question
          question={"What does Initium AI do?"}
          answer={
            "Initium AI is a full-service SaaS company dedicated to helping you turn your ideas into fully funded projects. Our platform offers comprehensive features that support grant applicants from start to finish. Acting as your copilot, we handle much of the busy work, allowing you to focus primarily on the writing."
          }
        />
        <Question
          question={"What is the difference between projects and spaces?"}
          answer={
            <span>
              A project is a collaborative environment designed for refining
              your idea and applying for a grant. Think of it as: One Project =
              One Idea & One Grant Application.
              <br />
              <br />
              Within each project, there are various elements called spaces.
              Spaces are designated areas for working on specific parts of the
              grant application process. Each space builds upon the others. For
              example, the Ideation Space is intended for brainstorming your
              idea, while the Grant Space is dedicated to searching for grant
              calls relevant to that idea.
            </span>
          }
        />
        <Question
          question={"How is my data protected?"}
          answer={
            "At Initium AI, we take data privacy very seriously. We employ industry-grade security standards across all our operations. All your data is encrypted both at rest and in transit using a GCP database within a Virtual Private Cloud. We adhere to least privilege practices for our infrastructure, ensuring that only the minimum number of people have access to your data. Additionally, our production environment is completely separated from our development and testing environments, further safeguarding your data. "
          }
        />
        <Question
          question={"What is the pricing?"}
          answer={
            "We are currently in Beta, which means that all of our services are free! Once we transition out of Beta, we will update our website and notify our users of the new pricing schedule."
          }
        />
        <Question
          question={"I'm not sure if I can use AI for my research proposals"}
          answer={
            <span>
              With the rapid rise of AI, there can be hesitation to adopt it for
              certain tasks. Currently, major funding organizations such as the
              NSF and NIH have only prohibited the use of AI tools for peer
              reviewers in the grant reviewing process, not for grant
              applicants.
              <br />
              <br />
              At Initium AI, we emphasize that our platform acts as a copilot,
              with our users remaining in full control. We do not write the
              proposal for you. Instead, we support you in various ways, such as
              helping to find relevant grant calls, providing feedback on your
              writing, offering a collaborative space to track your action
              items, etc.
            </span>
          }
        />
      </div>
    </div>
  );
}
