export default function Error() {
  return (
    <div className="h-screen w-screen flex justify-center items-center p-24">
      <div className="max-w-screen-lg flex flex-col gap-y-6">
        <div className="text-7xl">
          Sorry, we couldn't find the page you were looking for.
        </div>
        <a className="text-xl text-fuschia font-bold hover:underline" href="/">Go back to home</a>
      </div>
    </div>
  );
}
