import AboutUs from "./AboutUs";
import Pricing from "./Pricing";
import FAQ from "./FAQ/FAQ";
import Product from "./Product/Product";
import Intro from "./Intro";
import MissionStatement from "./MissionStatement";
import Support from "./Support";

function Home() {
  return (
    <>
      <Intro />
      <Product />
      <MissionStatement />
      <Support />
      <AboutUs />
      <Pricing />
      <FAQ />
    </>
  );
}

export default Home;
